import * as DateFns from 'date-fns'
import * as ReactRedux from 'react-redux'
import * as ReactRouterRedux from 'react-router-redux'
import { cookie } from 'redux-effects-universal-cookie'

import * as Configuration from '../configuration'
import * as Session from '../session'
import * as Notifications from '../notifications'
import * as Ui from '../ui'

import { LoginForm as Component } from './login-form'

export const LoginForm = ReactRedux.connect(
  state => ({
    clientType: Configuration.getClientType(state.configuration),
  }),
  dispatch => ({
    onSubmit: data => {
      dispatch(
        Session.login(data.email, data.password, data.clientType, {
          success: () => [
            Notifications.dismissAll(),
            ReactRouterRedux.push('/'),
            cookie('hasLoggedIn', true, {
              expires: DateFns.addDays(new Date(), 180),
              httpOnly: false,
              path: '/',
            }),
          ],
        })
      )
    },
    onRegister: () => dispatch(Ui.toggleVisibility('register', true)),
  })
)(Component)
