import * as ReactRedux from 'react-redux'
import * as R from 'ramda'
import * as ReactRouterDom from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'
import Url from 'url'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Forms from '@rushplay/forms'
import * as T from '@rushplay/theme'
import styled from '@emotion/styled'

import * as CombinedSelectors from '../combined-selectors'
import * as FormComponents from '../form-components'
import { AltButtonInverted } from '../components/common/button-simple'
import { H2 } from '../components/common/headings'
import { HtmlContent } from '../components/common/html-content'

function SubmitButton(props) {
  const form = Forms.useFormContext()
  const errors = Forms.getFormErrors(form.state)

  return (
    <AltButtonInverted disabled={R.not(R.isEmpty(errors))} type="submit">
      {props.children}
    </AltButtonInverted>
  )
}

SubmitButton.propTypes = {
  children: PropTypes.string,
}

export const Link = styled(Common.Link)`
  text-decoration: underline;
  letter-spacing: 1px;
  color: ${T.color('darkgray')};
  &:hover {
    color: ${T.color('darkblue')};
  }
`

export function PayAndPlayLoginForm() {
  const i18n = I18n.useI18n()
  const minDepositAmount = ReactRedux.useSelector(
    CombinedSelectors.getMinDepositAmountInCents
  )
  const initialDepositAmount = ReactRedux.useSelector(
    CombinedSelectors.getInitialAmount
  )
  const currency = ReactRedux.useSelector(CombinedSelectors.getCurrency)

  const history = ReactRouterDom.useHistory()

  const dataSchema = React.useMemo(
    () => ({
      type: 'object',
      properties: {
        amount: {
          type: 'number',
          minimum: minDepositAmount,
        },
      },
      required: ['amount'],
    }),
    [minDepositAmount]
  )

  return (
    <Common.Box
      display="flex"
      height="100%"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Common.Box>
        <H2 align="center" color="simpleorange" style={{ fontSize: '24px' }}>
          {i18n.translate('pay-and-play.title')}
        </H2>
        <Common.Space mt="1" mb="0">
          <Common.Text
            textAlign="center"
            textTransform="uppercase"
            color="darkgray"
            letterSpacing="1"
            lineHeight="32px"
            fontSize="1"
            fontWeight="bold"
          >
            {i18n.translate('pay-and-play.deposit-description')}
          </Common.Text>
        </Common.Space>
        <Forms.Provider
          name="pay-and-play"
          schema={dataSchema}
          onSubmit={(errors, data) => {
            if (R.isEmpty(errors)) {
              history.push(
                Url.format({
                  pathname: '/deposit/process',
                  query: {
                    flow: 'deposit',
                    amount: data.amount,
                    successRedirectUrl: '/',
                  },
                })
              )
            }
          }}
        >
          <React.Fragment>
            <FormComponents.WalletInputField
              scope="#/properties/amount"
              currency={currency}
              initialValue={initialDepositAmount * 100}
            />

            <Common.Box display="flex" justifyContent="center" mt="0" mb="1">
              <FormComponents.FieldResponse scope="#/properties/amount" />
            </Common.Box>

            <Common.Box
              textAlign="center"
              fontSize="0"
              mt="1"
              mb="2"
              color="darkgray"
              letterSpacing="1px"
              lineHeight="20px"
            >
              <HtmlContent
                html={{
                  __html: i18n.translate('pay-and-play.terms-and-conditions'),
                }}
                styleOverrides={{
                  a: {
                    color: 'inherit',
                    '&:hover': {
                      color: 'darkblue',
                    },
                  },
                  lineHeight: 1.5,
                }}
              />
            </Common.Box>

            <Common.Flex justifyContent="center">
              <SubmitButton>
                {i18n.translate('pay-and-play.login-and-deposit-cta')}
              </SubmitButton>
            </Common.Flex>
          </React.Fragment>
        </Forms.Provider>

        <Common.Box display="flex" justifyContent="center" mt="1" mb="0">
          <Link
            to={Url.format({
              pathname: '/login',
              query: {
                flow: 'normal',
                successRedirectUrl: '/',
              },
            })}
            textAlign="center"
            fontSize="0"
          >
            {i18n.translate('pay-and-play.login-cta')}
          </Link>
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}
