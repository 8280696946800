import * as React from 'react'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import { Card } from '../components/common/card'

import { EmailLoginForm } from './email-login-form'
import { PayAndPlayLoginForm } from './pay-and-play-login-form'

const Background = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  ${css({
    backgroundColor: 'simpleorange',
    clipPath: [
      'ellipse(150% 285px at 50% 0%)',
      null,
      'ellipse(100% 285px at 50% 0%)',
    ],
  })}
`

export function DualLoginForm() {
  return (
    <React.Fragment>
      <Background />
      <Common.Box
        display="flex"
        justifyContent="center"
        flexDirection={[null, 'column', 'row']}
        height="fit-content"
      >
        {[PayAndPlayLoginForm, EmailLoginForm].map((LoginForm, i) => (
          <Card
            padding={null}
            position="relative"
            key={i}
            fontFamily="head"
            width={[null, '100%', '310px']}
            mx={[null, null, 0]}
            mb="1"
            mt="3"
            px="1"
            py="2"
          >
            <LoginForm />
          </Card>
        ))}
      </Common.Box>
    </React.Fragment>
  )
}
