import * as ReactRedux from 'react-redux'
import * as ReactRouterRedux from 'react-router-redux'
import * as R from 'ramda'
import * as DateFns from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import { cookie } from 'redux-effects-universal-cookie'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as T from '@rushplay/theme'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import * as Ui from '../ui'
import * as Constants from '../constants'
import * as Configuration from '../configuration'
import * as Session from '../session'
import * as Notifications from '../notifications'
import * as FormComponents from '../form-components'
import {
  AltButtonInverted,
  PrimaryButton,
} from '../components/common/button-simple'
import { H2 } from '../components/common/headings'
import { HtmlContent } from '../components/common/html-content'

const dataSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      pattern: Constants.EmailPattern,
    },
    password: {
      type: 'string',
      minLength: 1,
    },
  },
  required: ['email', 'password'],
}

export const Link = styled(Common.Link)`
  text-decoration: underline;
  letter-spacing: 1px;
  color: ${T.color('darkgray')};
  &:hover {
    color: ${T.color('darkblue')};
  }
`

export function EmailLoginForm() {
  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()
  const clientType = ReactRedux.useSelector(state =>
    Configuration.getClientType(state.configuration)
  )

  const onSubmit = React.useCallback(
    data => {
      dispatch(
        Session.login(data.email, data.password, clientType, {
          success: () => [
            Notifications.dismissAll(),
            ReactRouterRedux.push('/'),
            cookie('hasLoggedIn', true, {
              expires: DateFns.addDays(new Date(), 180),
              httpOnly: false,
              path: '/',
            }),
          ],
        })
      )
    },
    [clientType]
  )

  return (
    <React.Fragment>
      <H2 align="center" color="simpleorange" style={{ fontSize: '24px' }}>
        {i18n.translate('email-login-form.title')}
      </H2>
      <Common.Space py="4px">
        <Forms.Provider
          name="login"
          schema={dataSchema}
          onSubmit={(errors, data) => {
            if (R.isEmpty(errors)) {
              onSubmit(data)
            }
          }}
        >
          <Common.Box display="grid" gridGap={1} pt={1}>
            <FormComponents.InputField
              autoComplete="email"
              placeholder="login-form.email"
              scope="#/properties/email"
            />
            <FormComponents.InputPasswordField
              autoComplete="password"
              placeholder="login-form.password"
              scope="#/properties/password"
            />
            <FormComponents.SubmitButton>
              {i18n.translate('login-form.login')}
            </FormComponents.SubmitButton>
          </Common.Box>
        </Forms.Provider>
        <Common.Box
          mt="1"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Link
            to="/password-recovery"
            fontSize={0}
            lineHeight={1.2}
            textAlign="center"
          >
            {i18n.translate('email-login-form.recover-password')}
          </Link>
        </Common.Box>
      </Common.Space>

      <Common.Box width="100%" border="1px solid rgba(0,0,0,.1)" mt="3" />

      <Common.Box
        fontSize="22px"
        fontWeight="bold"
        textAlign="center"
        color="simpleorange"
        my="1"
      >
        {i18n.translate('email-login-form.register-title')}
      </Common.Box>

      <Common.Box
        color="darkgray"
        fontSize="0"
        textAlign="center"
        mb="1"
        letterSpacing="1px"
        lineHeight="20px"
      >
        <HtmlContent
          html={{
            __html: i18n.translate('pay-and-play.terms-and-conditions'),
          }}
          styleOverrides={{
            a: {
              color: 'inherit',
              '&:hover': {
                color: 'darkblue',
              },
            },
            lineHeight: 1.5,
          }}
        />
      </Common.Box>

      <Common.Box
        as={PrimaryButton}
        onClick={() => dispatch(Ui.toggleVisibility('register', true))}
        display="flex"
        margin="0px auto"
      >
        {i18n.translate('emai-login-form.register-cta')}
      </Common.Box>
    </React.Fragment>
  )
}

export function SubmitButton(props) {
  const i18n = I18n.useI18n()

  return (
    <Common.Box display="flex" justifyContent="center" mt="0">
      <AltButtonInverted {...props}>
        {i18n.translate(props.children)}
      </AltButtonInverted>
    </Common.Box>
  )
}

SubmitButton.propTypes = {
  children: PropTypes.node,
}
