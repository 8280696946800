import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as ReactRouterRedux from 'react-router-redux'
import PropTypes from 'prop-types'
import querystring from 'querystring'

import * as Analytics from '@rushplay/analytics'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Player from './player'
import * as Constants from './constants'
import * as CombinedSelectors from './combined-selectors'
import * as Notifications from './notifications'
import * as Session from './session'
import * as Utils from './utils'
import { HtmlContent } from './components/common/html-content'
import { Payer } from './payer'
import { analyticsRegistration } from './analytics'

function TermsAndConditions() {
  const i18n = I18n.useI18n()
  const query = Utils.Hooks.useQuery()
  const showTermsAndConditions = R.hasPath(['tc'], query)

  if (!showTermsAndConditions) {
    return null
  }

  return (
    <Common.Space pt={2} pb={3}>
      <Common.Text textAlign="center" fontSize="0">
        <HtmlContent
          html={{
            __html: i18n.translate('deposit.terms-and-privacy'),
          }}
        />
      </Common.Text>
    </Common.Space>
  )
}

export function PayNPlayTransaction(props) {
  const dispatch = ReactRedux.useDispatch()
  const history = ReactRouterDom.useHistory()
  const location = ReactRouterDom.useLocation()
  const query = querystring.parse(R.tail(location.search))
  const amount =
    props.amount || (query.amount ? parseInt(query.amount) : undefined)

  const transactionType =
    props.flow === 'normal' || query.flow === 'normal'
      ? Constants.TransactionType.AUTH
      : Constants.TransactionType.DEPOSIT

  const welcomeOfferId = ReactRedux.useSelector(state =>
    CombinedSelectors.getWelcomeOfferIdIfClaimable(state, {
      amountCents: amount,
      orderType: transactionType,
    })
  )
  const previousLocation = React.useRef(history.previousLocation)
  const successRedirectUrl = `/?redirectUrl=${encodeURIComponent(
    props.successRedirectUrl ||
      query.successRedirectUrl ||
      R.path(['current', 'pathname'], previousLocation) ||
      '/'
  )}`

  const handleFailure = React.useCallback(
    payload => {
      if (payload.errors) {
        R.forEach(error => {
          dispatch(
            Notifications.add(
              {
                message: error.message,
                variables: Utils.formatErrorVariables({
                  ...error,
                  ...error.variables,
                }),
              },
              error.messageType || 'error'
            )
          )
        }, payload.errors)
      } else if (payload.error) {
        dispatch(
          Notifications.add(
            {
              message: payload.error.message,
              variables: payload.error.variables,
            },
            'error'
          )
        )
      }
    },
    [dispatch]
  )

  function handleSuccess(sessionToken, container, error = {}) {
    const [, sessionRequest] = dispatch(Session.refresh(sessionToken))
    const isDeposit = transactionType === Constants.TransactionType.DEPOSIT

    sessionRequest.then(() => {
      dispatch([
        analyticsRegistration(),
        isDeposit &&
          !error.message &&
          container !== 'brite' &&
          Player.fetch({ success: Analytics.deposit }),
        ReactRouterRedux.replace(successRedirectUrl),
      ])
    })

    if (error.message) {
      dispatch(
        Notifications.add(
          {
            message: error.message,
          },
          'error'
        )
      )
    }
  }

  return (
    <React.Fragment>
      <TermsAndConditions />
      <Common.Box height="520px">
        <Payer
          transactionType={transactionType}
          amountCents={amount}
          offerId={welcomeOfferId}
          onFailure={handleFailure}
          onSuccess={({ sessionToken, container, error }) =>
            handleSuccess(sessionToken, container, error)
          }
          onCancel={() =>
            history.push(
              R.path(['current', 'pathname'], previousLocation) || '/'
            )
          }
        />
      </Common.Box>
    </React.Fragment>
  )
}

PayNPlayTransaction.defaultProps = {
  flow: 'normal',
  failureRedirectUrl: '/deposit',
}

PayNPlayTransaction.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  failureRedirectUrl: PropTypes.string,
  flow: PropTypes.string,
  successRedirectUrl: PropTypes.string,
}

export default PayNPlayTransaction
