import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Forms from '@rushplay/forms'

import * as Constants from '../constants'
import * as FormComponents from '../form-components'
import { Card } from '../components/common/card'
import { Logotype } from '../components/common/logotype'

const dataSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      pattern: Constants.EmailPattern,
    },
    password: {
      type: 'string',
      minLength: 1,
    },
  },
  required: ['email', 'password'],
}

export function LoginForm(props) {
  const i18n = I18n.useI18n()

  return (
    <Common.Box margin="auto" maxWidth="400px">
      <Forms.Provider
        name="login"
        schema={dataSchema}
        onSubmit={(errors, data) => {
          if (R.isEmpty(errors)) {
            props.onSubmit({ ...data, clientType: props.clientType })
          }
        }}
      >
        <Card>
          <Common.Space px={1}>
            {props.logotype && (
              <Common.Box display="flex" justifyContent="center" py={1}>
                <Logotype alt dark width="200px" />
              </Common.Box>
            )}

            <Common.Box display="grid" gridGap={1} px={0} pt={0}>
              <FormComponents.InputField
                autoComplete="email"
                inputMode="email"
                placeholder="login-form.email"
                scope="#/properties/email"
              />

              <FormComponents.InputPasswordField
                autoComplete="password"
                placeholder="login-form.password"
                scope="#/properties/password"
              />
            </Common.Box>

            <Common.Box p={0} display="flex" justifyContent="flex-end">
              <Common.Link to="/password-recovery">
                <Common.Text color="gray" fontSize={0} lineHeight={1.2}>
                  {i18n.translate('login-form.forgotpassword')}
                </Common.Text>
              </Common.Link>
            </Common.Box>

            <Common.Box pt={0} display="flex" justifyContent="center">
              <FormComponents.SubmitButton>
                {i18n.translate('login-form.login')}
              </FormComponents.SubmitButton>
            </Common.Box>

            <Common.Box pt={1} display="flex" justifyContent="center">
              <Common.Text
                fontSize={0}
                lineHeight={1.2}
                onClick={props.onRegister}
                style={{ cursor: 'pointer' }}
              >
                {i18n.translate('login-form.not-a-member-register')}
              </Common.Text>
            </Common.Box>
          </Common.Space>
        </Card>
      </Forms.Provider>
    </Common.Box>
  )
}

LoginForm.propTypes = {
  clientType: PropTypes.string,
  logotype: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onRegister: PropTypes.func.isRequired,
}
