import * as R from 'ramda'
import * as recompose from 'recompose'
import React from 'react'
import querystring from 'querystring'
import { connect } from 'react-redux'

import * as Jurisdiction from '@rushplay/compliance/jurisdiction'

import * as Configuration from '../configuration'
import { DualLoginForm } from '../dual-login-form'
import { LoginForm } from '../login-form'
import { PayNPlayTransaction } from '../pay-n-play-transaction'

function mapStateToProps(state, props) {
  const query = querystring.parse(R.tail(props.history.location.search))

  return {
    features: Configuration.getFeatures(state.configuration),
    manualSignup: Jurisdiction.getManualSignUpAllowed(state.jurisdiction),
    pnpLoginAllowed:
      Jurisdiction.getTrustlySignUpAllowed(state.jurisdiction) ||
      Jurisdiction.getBriteKycSignUpAllowed(state.jurisdiction),
    flow: query.flow,
  }
}

export const Login = R.compose(
  connect(mapStateToProps),
  recompose.branch(
    props => R.not(props.manualSignup) || props.flow,
    recompose.renderComponent(() => <PayNPlayTransaction />)
  ),
  recompose.branch(
    props => props.manualSignup && props.pnpLoginAllowed,
    recompose.renderComponent(DualLoginForm)
  )
)(LoginForm)
